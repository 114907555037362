<template>
  <v-container class="requests-category-modify">
    <entity-modify-header class="mb-10" :title="title">
      <v-btn v-if="isEdit" color="secondary" elevation="0" large class="primary--text" @click="archiveCategory">
        <span v-if="isArchived">{{ $t('button.unarchive') }}</span>
        <span v-else>{{ $t('button.archive') }}</span>

        <v-icon v-if="isArchived" size="20" class="ms-1">mdi-archive-arrow-up</v-icon>
        <v-icon v-else size="20" class="ms-1">mdi-archive-arrow-down</v-icon>
      </v-btn>
    </entity-modify-header>

    <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
      <template #footer="{ valid }">
        <v-row class="mt-10">
          <v-col>
            <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
          </v-col>
          <v-col>
            <v-btn type="submit" color="primary" block :disabled="!valid" :loading="isUpload">
              {{ submitButtonText }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </form-builder>
  </v-container>
</template>

<script>
// Models
import { schemaCategory as schema } from '@/schemas/request.schema';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Components
import FormBuilder from '@/components/schema/FormBuilder.vue';
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
// import analyticsService from '@/services/analytics';

// Services
import requestsService from '@/services/requests';
import notificationService from '@/services/notification';

import { REQUESTS } from '@/constants/routes';

export default {
  name: 'RequestsCategoryModify',

  components: {
    FormBuilder,
    EntityModifyHeader,
  },

  inject: ['media'],

  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: Number, default: null },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
      isUpload: false,
    };
  },

  computed: {
    title() {
      return this.isEdit ? this.$t('requests.edit_category') : this.$t('requests.add_category');
    },
    submitButtonText() {
      return this.isEdit ? this.$t('button.edit_category') : this.$t('button.add_category');
    },
    isArchived() {
      return this.isEdit && this.initialData.isArchived;
    },
    backRoute() {
      return this.$route.params.prevPage || { name: REQUESTS };
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      requestsService
        .getCategoryById(this.id)
        .then(category => {
          // @todo после исправления бэка убрать форматирование
          this.initialData = {
            ...category,
            categoryType: category.categoryType,
          };
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    async submit(data) {
      if (this.isUpload) {
        return;
      }
      this.isUpload = true;
      const body = { ...data };
      body.categoryType = data.categoryType.id;

      const saveCategory = this.isEdit ? requestsService.updateCategory : requestsService.createCategory;

      try {
        await saveCategory(body, this.id);
        // analyticsService.track(this.isEdit ? EDIT_EMPLOYEES : CREATE_EMPLOYEES);
        notificationService.success(
          this.isEdit ? this.$t('requests.category_edited') : this.$t('requests.category_created'),
          2000
        );
        this.$router.push(this.backRoute);
      } catch (error) {
        this.$options.notificationItem = notificationService.error(
          `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
        );
      } finally {
        this.isUpload = false;
      }
    },

    async archiveCategory() {
      if (this.isUpload) {
        return;
      }
      this.isUpload = true;

      requestsService
        .archiveCategory(this.id, !this.initialData.isArchived)
        .then(() => {
          // analyticsService.track(this.isEdit ? EDIT_EMPLOYEES : CREATE_EMPLOYEES);
          notificationService.success(this.$t('requests.category_is_archived'), 2000);
          this.$router.push({ name: REQUESTS });
          this.initialData.isArchived = !this.initialData.isArchived;
        })
        .catch(error => {
          this.$options.notificationItem = notificationService.error(
            `${this.$t('error.found_errors')} ${Object.keys(error?.response?.data).join(', ')}`
          );
        })
        .finally(() => {
          this.isUpload = false;
        });
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.requests-category-modify {
  max-width: 548px !important;
}
</style>
